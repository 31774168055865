const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=thimbles&no_redirect&no_op_launcher',
  cheatsParams:  ['force_win', 'force_keys'],
  baseData: {
    ver: '1.1'
  }
};

export const requestConfig = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();

  const response = await sendRequest({ }, 'config');

  return response;
};

export const startGame = async (mode, thimble, betIndex, hero) => {
  const data = { mode, betIndex, thimble, hero };
  const response = await sendRequest(data, 'roll');
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response
};

window.alwaysWin = 0;

export const startGameCheatingGame = async (mode, thimble, betIndex, hero) => {
  const data = { id: 'roll', debug_win: window.alwaysWin, mode, betIndex, thimble, hero };
  const response = await sendRequest(data, 'roll');
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const getBonusGame = async (chest = 0) => {
  const data = { chest };
  const response = await sendRequest(data, 'bonus');
  if (!response) return;
  OPWrapperService.ControllerStatistic.win = response.win_amount;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const getKeys = async () => {
  return await sendRequest({ }, 'debug');
};

export const requestCoinMinigame = async () => {
  const response = await sendRequest({ }, 'minigame');
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const collect = async () => {
  const response  = await sendRequest({ }, 'collect');
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  OPWrapperService.ControllerStatistic.win = response.win_amount;
  OPWrapperService.realityCheck.blockedRealityCheck = false;
  return response;
};

let waitingForResponse = false;
const sendRequest = async (data, id, urlPath = 'game_request') => {
  if (waitingForResponse) {
    console.warn('Already waiting for response.');
    return;
  }
  data.id = id;
  waitingForResponse = true;
  const response = await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
  waitingForResponse = false;
  return response;
};

window.getKeys = getKeys;
window.requestCoin = requestCoinMinigame;
window.collect = collect;
