// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./messageWindow/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./sideBar/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./betSelector/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./infoPanel/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./gameLimitsWindow/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./realityCheck/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./freeBets/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_7___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./fullscreen/index.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/LEAGUEGOTHIC.TTF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/MyriadProBold.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/UBUNTU-R.TTF");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/UBUNTU-L.TTF");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/fonts/UBUNTU-B.TTF");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/fonts/BEBASNEUE-REGULAR.OTF");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"League Gothic\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"MyriadProBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"UbuntuRegular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"UbuntuLight\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: \"Ubuntu bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n@font-face {\n  font-family: \"Bebas Neue\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\nhtml {\n  touch-action: none;\n}\n\n* {\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n  -webkit-tap-highlight-color: transparent;\n}\n\n.opWrapper {\n  position: absolute;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  user-select: none;\n  pointer-events: none;\n  font-family: \"Inter\", \"Ubuntu bold\", \"Ubuntu\", ui-sans-serif, system-ui, sans-serif;\n  font-size: 22px;\n  color: #FFFFE1;\n  z-index: 200;\n}\n\n.opWrapper.enable {\n  pointer-events: all;\n}\n\n@media only screen and (max-width: 700px) {\n  .opWrapper {\n    font-size: 16px;\n  }\n  .opWrapper .container {\n    min-height: 300px;\n  }\n}\n@media only screen and (max-height: 550px) {\n  .opWrapper .container {\n    min-height: 300px;\n  }\n}", ""]);
// Exports
module.exports = exports;
